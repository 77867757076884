<div class="container">
    <div class="card card-container">
        <h2>Herstel wachtwoord</h2>

        <!-- No token present, show request token form -->
        <ng-container *ngIf="!token">
            <p>
                Voer hieronder uw e-mailadres in. We sturen u vervolgens een
                e-mail waarmee u een nieuw wachtwoord kunt kiezen.<br />
            </p>
            <div *ngIf="error" class="alert alert-danger">
                {{ error }}
            </div>
            <form
                class="form-signin"
                name="resetForm"
                (ngSubmit)="requestReset()"
                *ngIf="!emailSent"
            >
                <div class="input-group">
                    <input
                        type="email"
                        id="inputEmail"
                        autocomplete="email"
                        class="form-control"
                        placeholder="E-mailadres"
                        required
                        autofocus
                        name="email"
                        [(ngModel)]="email"
                    />
                </div>
                <button
                    class="btn btn-lg btn-primary btn-block btn-signin"
                    type="submit"
                >
                    Herstel wachtwoord
                </button>
            </form>
            <p *ngIf="emailSent">
                Er is een e-mail verstuurd met daarin een link. Volg de
                instructies in de e-mail op om een nieuw wachtwoord te maken.
            </p>
        </ng-container>
        <!-- Token present, show form to reset password -->
        <ng-container *ngIf="token">
            <p>
                Maak een nieuw wachtwoord aan dat nog niet eerder is
                gebruikt.<br />
            </p>
            <div *ngIf="error" class="alert alert-danger">
                {{ error }}
            </div>

            <form
                class="form-signin"
                [formGroup]="resetForm"
                (ngSubmit)="resetPassword()"
            >
                <input
                    type="email"
                    class="form-control"
                    placeholder="E-mailadres"
                    [ngClass]="{
                        error:
                            f.email.errors && (f.email.dirty || f.email.touched)
                    }"
                    formControlName="email"
                />
                <div *ngIf="f.email.errors" class="error">
                    <div *ngIf="f.email.errors.email">
                        Voer een geldig email adres in.
                    </div>
                </div>

                <div class="input-group">
                    <input
                        id="inputPassword"
                        class="form-control"
                        [ngClass]="{
                            error:
                                f.password.errors &&
                                (f.password.dirty || f.password.touched)
                        }"
                        placeholder="Nieuw wachtwoord"
                        [type]="hide1 ? 'password' : 'text'"
                        formControlName="password"
                    />
                    <div class="input-group-append" (click)="hide1 = !hide1">
                        <div class="input-group-text">
                            <mat-icon>{{
                                hide1 ? 'visibility_off' : 'visibility'
                            }}</mat-icon>
                        </div>
                    </div>
                </div>

                <div *ngIf="f.password.errors">
                    <div *ngIf="f.password.errors.pattern" class="error">
                        Het wachtwoord moet uit minimaal 8 karakters bestaan en
                        minimaal 1 hoofdletter, 1 nummer en 1 leesteken
                        bevatten.
                    </div>
                </div>

                <div class="input-group">
                    <input
                        class="form-control"
                        [ngClass]="{
                            error:
                                f.confirmPassword.errors &&
                                (f.confirmPassword.dirty ||
                                    f.confirmPassword.touched)
                        }"
                        placeholder="Bevestig wachtwoord"
                        [type]="hide2 ? 'password' : 'text'"
                        formControlName="confirmPassword"
                    />
                    <div class="input-group-append" (click)="hide2 = !hide2">
                        <div class="input-group-text">
                            <mat-icon>{{
                                hide2 ? 'visibility_off' : 'visibility'
                            }}</mat-icon>
                        </div>
                    </div>
                </div>

                <div
                    *ngIf="
                        resetForm.get('password').value !==
                        resetForm.get('confirmPassword').value
                    "
                >
                    <div class="error">De wachtwoorden komen niet overeen.</div>
                </div>
                <button
                    class="btn btn-lg btn-primary btn-block btn-signin"
                    [disabled]="
                        !resetForm.valid &&
                        resetForm.get('password').value !==
                            resetForm.get('confirmPassword').value
                    "
                    type="submit"
                >
                    Opslaan
                </button>
            </form>
        </ng-container>
    </div>
    <div
        id="image"
        [style.backgroundImage]="'url(' + configService.backgroundImage + ')'"
    ></div>
    <video muted autoplay loop id="myVideo" class="fade-in">
        <source [src]="configService.backgroundVideo" type="video/mp4" />
    </video>
</div>
