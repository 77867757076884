import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService, ConfigService, ExcelService } from 'app/_services';
import { HttpClient } from '@angular/common/http';
import { Feature } from 'ol';
import GeoJSON from 'ol/format/GeoJSON.js';
import { environment } from 'environments/environment';
export interface SelectExportDialogData {
    features: any;
    title: string;
}
@Component({
    selector: 'select-export-dialog',
    templateUrl: 'select-export.dialog.html',
    styleUrls: ['select-export.dialog.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class SelectExportDialogComponent {
    loading = false;
    error: string;

    geojson: any;
    geojsonfile: any;

    url = 'https://fme.gisarts.nl';
    version = 'v3';

    title = 'result';

    repository = 'Samples';
    workspace = 'easyTranslator.fmw';

    token = '';

    type: any;
    // ACAD, CSV, GEOJSON, GPX, IGDS, MIF, MITAB, OGCKML, SDF3, SHAPEFILE, SQLITE3FDO, GML
    constructor(
        public dialogRef: MatDialogRef<SelectExportDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: SelectExportDialogData,
        private excelService: ExcelService,
        private http: HttpClient,
        private authService: AuthService,
        private configService: ConfigService
    ) {

        if (environment.development) this.workspace = 'easyTranslator_dev.fmw'
        this.http
            .get(environment.api_base_url + '/key/fme')
            .toPromise()
            .then((res: any) => {
                this.token = res;
            });

        this.title = this.data.title;
        this.geojson = new GeoJSON().writeFeaturesObject(this.data.features);
        this.geojsonfile = new Blob([JSON.stringify(this.geojson)], {
            type: 'application/json'
        });
    }

    export(type) {
        this.loading = true;

        switch (type) {
            case 'xlsx':
                const extractedProperties: any[] = [];

                this.data.features.forEach((feature: Feature) => {
                    const properties: any = {};
                    const keys = feature.getKeys();

                    keys.forEach((key: string) => {
                    if (key !== 'boundedBy' && key !== 'type' && key !== 'resource' && key !== 'id' && key !== 'count' && key !== 'gml_parent_property' && key !== 'gml_parent_id' && key !== 'gml_id' && key !== 'punt' && key !== 'lijn' && key !== 'jaar') {
                        properties[key] = feature.get(key);
                    }
                    });

                    extractedProperties.push(properties);
                });

                this.excelService.exportAsExcelFile(
                    extractedProperties,
                    'Export'
                );
                this.dialogRef.close();

                break;
            case 'GEOJSON':
                const url = URL.createObjectURL(this.geojsonfile);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'result';
                link.click();
                URL.revokeObjectURL(url);
                this.dialogRef.close();

                break;
            case 'GEOPACKAGE':
                this.type = type;
                this.workspace = 'geopackage.fmw';
                this.uploadResourceFile(this.geojsonfile);
                break;
            default:
                this.type = type;
                this.workspace = 'easyTranslator.fmw'
                this.uploadResourceFile(this.geojsonfile);
                break;
        }
    }

    uploadResourceFile(file) {
        let url =
            this.url +
            '/fmedataupload/' +
            this.repository +
            '/' +
            this.workspace;
        url = url + '?opt_fullpath=true&detail=high&token=' + this.token;

        const date = new Date().getTime().toString();
        const name = this.authService.id()
            ? this.authService.id() + '_' + date + '.geojson'
            : this.configService.config().id + date + '.geojson';

        var formData = new FormData();
        formData.append('file', file, name);

        this.http
            .post(url, formData)
            .toPromise()
            .then((response: any) => {
                console.log(response);
                this.getTransformedFile(name);
            })
            .catch(error => {});
    }

    getTransformedFile(name) {
        let url =
            this.url +
            '/fmedatadownload/' +
            this.repository +
            '/' +
            this.workspace;
        url = url + '?detail=high&token=' + this.token;
        url =
            `${url}&SourceDataset_GENERIC=$(FME_DATA_REPOSITORY)/Samples/${this.workspace}/admin/${name}`;
        url = url + '&opt_responseformat=json';
        url = url + '&SourceFormat=GUESS_FROM_EXTENSION';
        url = url + '&GENERIC_OUT_BASE_NAME_GENERIC=' + this.title;
        url = url + '&DestinationFormat=' + this.type;
        url = url + '&accept=contents';

        this.http
            .get(url)
            .toPromise()
            .then((response: any) => {
                if (response.serviceResponse.url) {
                    // this.http.get(response.serviceResponse.url + '?token=' + this.token, {  }).toPromise()
                    // .then((response: any) => {
                    //     FileSaver.saveAs(response);
                    // });

                    window.open(response.serviceResponse.url, '_blanc');
                }
                this.dialogRef.close();
            })
            .catch(error => {});
    }
}
