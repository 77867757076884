<div
    ngResizable
    [rzHandles]="'n'"
    [hidden]="gridService.openStatus() === 'closed'"
    class="grid"
>
    <div class="grid-content">
        <mat-tab-group
            preserveContent
            (selectedTabChange)="onTabChange($event)"
        >
            <mat-tab
                *ngFor="let type of features; let i = index"
                animationDuration="0ms"
            >
                <ng-template mat-tab-label>
                    {{ type.title }}
                    <a mat-icon-button (click)="export(type)" target="_blank">
                        <mat-icon>archive</mat-icon>
                    </a>
                </ng-template>
                <!-- <button mat-icon-button class="edit" (click)="editTable(type)">
                    <mat-icon>edit</mat-icon>
                </button> -->
                <div [id]="'grid.' + i">
                    <div
                        *ngIf="
                            type.type === 'columns' && type.columns.length > 0
                        "
                    >
                        <table
                            mat-table
                            [dataSource]="type"
                            [matSortActive]="
                                type.columns[1]?.name
                                    ? type.columns[1].name
                                    : type.columns[0].name
                            "
                            matSortDirection="asc"
                            (matSortChange)="sortColumn($event)"
                            matSort
                            #sort{{i}}="matSort"
                        >
                            <ng-container
                                *ngFor="let column of type.columns"
                                [matColumnDef]="column.prop"
                            >
                                <th
                                    mat-header-cell
                                    mat-sort-header
                                    *matHeaderCellDef
                                >
                                    <b>{{ column.name }}</b>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngSwitch]="column.type"
                                >
                                    <a
                                        *ngSwitchCase="'link'"
                                        href="{{ element[column.prop] }}"
                                        target="_blank"
                                    >
                                        <span *ngIf="element[column.prop]">
                                            Detailinformatie
                                        </span>
                                    </a>
                                    <img
                                        *ngSwitchCase="'image'"
                                        class="image"
                                        [src]="element[column.prop]"
                                        (click)="
                                            openImageDialog(
                                                element[column.prop]
                                            )
                                        "
                                    />
                                    <a
                                        *ngSwitchCase="'vrimage'"
                                        [routerLink]="[]"
                                        (click)="
                                            openVRDialog(element[column.prop])
                                        "
                                        >Open 360 Afbeelding</a
                                    >
                                    <a
                                        *ngSwitchCase="'video'"
                                        [routerLink]="[]"
                                        (click)="
                                            openVideoDialog(
                                                element[column.prop]
                                            )
                                        "
                                        >Bekijk video</a
                                    >
                                    <span *ngSwitchDefault>{{
                                        element[column.prop]
                                    }}</span>
                                </td>
                            </ng-container>
                            <tr
                                mat-header-row
                                *matHeaderRowDef="type.columnNames"
                            ></tr>
                            <tr
                                mat-row
                                *matRowDef="let row; columns: type.columnNames"
                                (click)="highlightFeature(row)"
                                class="table-row"
                            ></tr>
                        </table>
                    </div>

                    <div
                        *ngIf="
                            type.type === 'line' ||
                            type.type === 'pie' ||
                            type.type === 'bar'
                        "
                        class="graph"
                    >
                        <canvas
                            baseChart
                            [datasets]="[type.graph]"
                            [labels]="type.labels"
                            [options]="chartOptions"
                            [type]="type.type"
                            type="bar"
                        >
                        </canvas>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
    <div class="grid-close">
        <button (click)="toggle()">
            <fa-icon [icon]="faAngleDown"></fa-icon>
        </button>
    </div>
</div>
