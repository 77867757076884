<div class="print-form mt-1">
    <form
        name="print"
        class="print-form"
        (ngSubmit)="print()"
        #printform="ngForm"
    >
        <button
            mat-mini-fab
            type="button"
            class="print-manual"
            color="secondary"
            (click)="manual()"
            matTooltip="Raadpleeg de handleiding."
        >
            <fa-icon [icon]="faInfo"></fa-icon>
        </button>

        <h4>Exporteren</h4>

        <fieldset class="container">
            <div class="row">
                <div class="col-12">
                    <mat-form-field class="w-100">
                        <input
                            matInput
                            placeholder="Titel"
                            [(ngModel)]="title"
                            name="title"
                        />
                        <mat-label>Titel</mat-label>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <mat-form-field>
                        <mat-select
                            placeholder="Layout"
                            [(ngModel)]="layout"
                            name="layout"
                            [(value)]="layout"
                            (selectionChange)="renderMap()"
                        >
                            <mat-option *ngFor="let l of layouts" [value]="l">{{
                                l
                            }}</mat-option>
                        </mat-select>
                        <mat-label>Formaat</mat-label>
                    </mat-form-field>
                </div>
            </div>

            <div class="row" *ngIf="printConfig()?.customScale">
                <div class="col-12">
                    <mat-form-field>
                        <mat-select
                            placeholder="Schaal"
                            [(ngModel)]="scale"
                            (selectionChange)="renderMap()"
                            name="scale"
                        >
                            <mat-option
                                *ngFor="let s of printScales"
                                [value]="s"
                                >1:{{ s }}</mat-option
                            >
                        </mat-select>
                        <mat-label>Schaal</mat-label>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-4">
                    <mat-form-field class="w-100">
                        <input
                            matInput
                            type="number"
                            min="0"
                            max="360"
                            placeholder="Rotatie"
                            [(ngModel)]="rotation"
                            name="rotation"
                            (change)="mapService.map().render()"
                        />
                        <mat-label>Rotatie</mat-label>
                    </mat-form-field>
                </div>

                <div class="col-8">
                    <mat-slider
                        color="primary"
                        class="w-75"
                        type="number"
                        min="0"
                        max="360"
                        value="0"
                        placeholder="Rotatie"
                        name="rotation"
                        (change)="mapService.map().render()"
                    >
                        <input
                            matSliderThumb
                            [(value)]="rotation"
                            type="range"
                        />
                    </mat-slider>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <mat-radio-group
                        [(ngModel)]="type"
                        name="type"
                        aria-label="Select an option"
                        (change)="mapService.map().render()"
                    >
                        <mat-radio-button color="primary" value="landscape"
                            >Liggend</mat-radio-button
                        >

                        <mat-radio-button color="primary" value="portrait"
                            >Staand</mat-radio-button
                        >
                    </mat-radio-group>
                </div>
            </div>

            <div class="row my-3">
                <div class="col-12">
                    <mat-slide-toggle
                        color="primary"
                        checked="true"
                        [(ngModel)]="info"
                        [ngModelOptions]="{ standalone: true }"
                    >
                        Print informatie
                    </mat-slide-toggle>
                </div>
            </div>

            <div class="row justify-content-center my-3">
                <div class="col-12">
                    <div class="px-3">
                        <button
                            type="submit"
                            [disabled]="!printform.form.valid"
                            mat-raised-button
                            class="w-100"
                        >
                            Maak een print
                        </button>
                    </div>
                </div>
            </div>
        </fieldset>
    </form>

    <mat-divider class="row my-3"></mat-divider>

    <div *ngIf="printConfig().customScale">
        <h5>Schaalniveau</h5>

        <table class="w-100">
            <tr>
                <td>1000 m</td>

                <td>1: 50.000</td>
            </tr>

            <tr>
                <td>500 m</td>

                <td>1: 25.000</td>
            </tr>

            <tr>
                <td>200 m</td>

                <td>1: 10.000</td>
            </tr>

            <tr>
                <td>100 m</td>

                <td>1: 5.000</td>
            </tr>

            <tr>
                <td>50 m</td>

                <td>1: 2.500</td>
            </tr>

            <tr>
                <td>40 m</td>

                <td>1: 2.000</td>
            </tr>

            <tr>
                <td>20 m</td>

                <td>1: 1.000</td>
            </tr>

            <tr>
                <td>10 m</td>

                <td>1: 500</td>
            </tr>

            <tr>
                <td>5 m</td>

                <td>1: 250</td>
            </tr>

            <tr>
                <td>4 m</td>

                <td>1: 200</td>
            </tr>
        </table>
    </div>

    <div id="template">
        <div id="printTmpl">
            <img src="" id="printImage" crossorigin="*" />

            <ng-container *ngIf="info">
                <!-- Template for duopp -->

                <div class="content" *ngIf="info">
                    <div class="client_image">
                        <div *ngIf="printConfig().image; else default">
                            <img
                                src="{{ printConfig().image }}"
                                crossorigin="anonymous"
                            />
                        </div>

                        <ng-template #default>
                            <img
                                src="{{
                                    environment.subDirectory
                                }}/assets/img/{{
                                    environment.loginPrefix
                                }}/logo_{{ environment.loginPrefix }}.png"
                                crossorigin="anonymous"
                            />
                        </ng-template>
                    </div>

                    <div class="text">
                        <ng-container *ngIf="title"
                            ><b class="title">Titel: {{ title }}</b> <br
                        /></ng-container>

                        <!-- <ng-container *ngIf="authService.name">Auteur: {{ authService.name }} <br></ng-container> -->

                        {{ date }} <br />

                        Formaat: {{ layout }} <br />

                        Schaal 1:{{ scale }}
                    </div>

                    <div class="column1">
                        <img
                            src="{{
                                environment.subDirectory
                            }}/assets/img/NorthArrow.png"
                            id="northArrow"
                            alt="north_arrow"
                            class="north_arrow"
                            crossorigin="anonymous"
                        />

                        <p>
                            <i
                                >Aan dit kaartproduct kunnen geen rechten worden
                                ontleend.</i
                            >
                        </p>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
