import { Injectable } from '@angular/core';
import { MapService } from './map.service';
import { Style, Fill, Stroke, Circle, Icon, Text } from 'ol/style.js';
import { Vector, Cluster } from 'ol/source';
import { Vector as layerVector } from 'ol/layer.js';
import { Feature } from 'ol';

@Injectable({
    providedIn: 'root'
})
/**
 * This service is to export all the color options and all the functions for the layers
 */
export class LayerService {
    layers = {
        drawingLayer: null as any,
        featureLayer: null as any,
        overlayLayer: null as any,
        outlineLayer: null as any,
        clusterLayer: null as any,
        markerLayer: null as any,
        measurementLayer: null as any,
        redliningLayer: null as layerVector<Vector>,
        dragAndDropLayer: null as any,
        overviewSelectedLayer: null as any,
        notificationLayer: null as any,
        createLayer: null as any,
        bufferLayer: null as any
    };

    readonly styleColors = {
        transparent: {
            fill: 'rgba(0, 0, 0, 0)',
            stroke: 'rgba(0, 0, 0, 0)'
        },
        blackstroke: {
            fill: 'rgba(0, 0, 0, 0)',
            stroke: 'rgba(0, 0, 0, 1)'
        },
        greenstroke: {
            fill: 'rgba(0, 0, 0, 0)',
            stroke: '#57cc43'
        },
        grey: {
            fill: 'rgba(100, 100, 100, 0.7)',
            stroke: '#646464'
        },
        lightgrey: {
            fill: 'rgba(150, 150, 150, 0)',
            stroke: '#646464'
        },
        red: {
            fill: 'rgba(244, 76, 65, 0.3)',
            stroke: '#f44c41'
        },
        blue: {
            fill: 'rgba(0, 51, 255, 0.3)',
            stroke: '#0033ff'
        },
        bluestroke: {
            fill: 'rgba(0, 0, 0, 0)',
            stroke: '#0033ff'
        },
        darkblue: {
            fill: 'rgba(23, 20, 127, 0.3)',
            stroke: '#17147f'
        },
        darkbrown: {
            fill: 'rgba(204, 153, 0, 0.5)',
            stroke: '#CC9900'
        },
        cyan: {
            fill: 'rgba(66, 194, 244, 0.3)',
            stroke: '#42c2f4'
        },
        green: {
            fill: 'rgba(87, 204, 67, 0.3)',
            stroke: '#57cc43'
        },
        purple: {
            fill: 'rgba(175, 65, 244, 0.3)',
            stroke: '#af41f4'
        },
        yellow: {
            fill: 'rgba(241, 244, 65, 0.3)',
            stroke: '#f1f441'
        },
        orange: {
            fill: 'rgba(244, 148, 65, 0.3)',
            stroke: '#f49441'
        },
        darkgreen: {
            fill: 'rgba(1, 128, 1, 0.3)',
            stroke: '#41f486'
        },
        magenta: {
            fill: 'rgba(255, 0, 255, 0.3)',
            stroke: '#ff00ff'
        },
        brown: {
            fill: 'rgba(73, 34, 0, 0.3)',
            stroke: '#492200'
        },
        bouwvergunningverleend: {
            fill: 'rgba(153, 51, 153, 0.3)',
            stroke: '#993399'
        },
        bouwgestart: {
            fill: 'rgba(51, 255, 51, 0.3)',
            stroke: '#33FF33'
        },
        sloopvergunningverleend: {
            fill: 'rgba(255, 153, 0, 0.3)',
            stroke: '#FF9900'
        },
        pandingebruiknietingemeten: {
            fill: 'rgba(51, 255, 255, 0.3)',
            stroke: '#33FFFF'
        },
        pandbuitengebruik: {
            fill: 'rgba(153, 153, 255, 0.3)',
            stroke: '#9999FF'
        },
        stripered: {
            fill: 'rgba(65, 244, 134, 0.5)',
            stroke: '#41f486'
        },
        stripegreen: {
            fill: 'rgba(247, 100, 74, 0.5)',
            stroke: '#f7644a'
        }
    };

    constructor(private readonly mapService: MapService) {}

    /**
     * Create a layer for drawing and editing features. Make sure we never have two.
     */
    drawingLayer(): any {
        if (!this.layers.drawingLayer) {
            const source: any = new Vector({ wrapX: false });

            this.layers.drawingLayer = new layerVector({
                source,
                style: new Style({
                    fill: new Fill({
                        color: 'rgba(255, 255, 255, 0.2)'
                    }),
                    stroke: new Stroke({
                        color: '#ffcc33',
                        width: 2
                    }),
                    image: new Circle({
                        radius: 7,
                        fill: new Fill({
                            color: '#ffcc33'
                        })
                    })
                })
            });

            this.mapService.map().addLayer(this.layers.drawingLayer);
        }

        return this.layers.drawingLayer;
    }

    /**
     * Create a layer that is used for showing features, this layer will not be editable
     */
    featureLayer(layerText = '', disableHighlight = false): any {
        if (!this.layers.featureLayer) {
            const source: any = new Vector({ wrapX: false });

            this.layers.featureLayer = new layerVector({
                source
            });

            this.layers.featureLayer.set('styleColors', this.styleColors);

            this.setStyle(this.layers.featureLayer);

            this.mapService.map().addLayer(this.layers.featureLayer);
        }

        return this.layers.featureLayer;
    }

    overlayLayer(): any {
        if (!this.layers.overlayLayer) {
            const source: any = new Vector({ wrapX: false });

            this.layers.overlayLayer = new layerVector({
                source,
                style: new Style({
                    fill: new Fill({
                        color: 'rgba(255, 186, 25, 0.2)'
                    }),
                    stroke: new Stroke({
                        color: '#ffba19',
                        width: 5
                    }),
                    image: new Circle({
                        radius: 7,
                        fill: new Fill({
                            color: 'rgba(255, 186, 25, 0.2)'
                        }),
                        stroke: new Stroke({
                            color: '#ffba19',
                            width: 5
                        })
                    })
                })
            });

            this.mapService.map().addLayer(this.layers.overlayLayer);
        }

        return this.layers.overlayLayer;
    }

    outlineLayer(): any {
        if (!this.layers.outlineLayer) {
            const source: any = new Vector({ wrapX: false });

            this.layers.outlineLayer = new layerVector({
                source,
                style: new Style({
                    fill: new Fill({
                        color: 'rgba(255, 186, 25, 0)'
                    }),
                    stroke: new Stroke({
                        color: 'rgba(255, 186, 25, 0.5)',
                        width: 3
                    }),
                    image: new Circle({
                        radius: 7,
                        fill: new Fill({
                            color: 'rgba(255, 186, 25, 0)'
                        }),
                        stroke: new Stroke({
                            color: 'rgba(255, 186, 25, 0.5)',
                            width: 3
                        })
                    })
                })
            });

            this.mapService.map().addLayer(this.layers.outlineLayer);
        }

        return this.layers.outlineLayer;
    }

    notificationLayer(): any {
        if (!this.layers.notificationLayer) {
            const source: any = new Vector();

            this.layers.notificationLayer = new layerVector({
                source,
                style: new Style({
                    fill: new Fill({
                        color: this.styleColors.magenta.fill
                    }),
                    stroke: new Stroke({
                        color: this.styleColors.magenta.stroke,
                        width: 3
                    }),
                    image: new Circle({
                        radius: 7,
                        fill: new Fill({
                            color: this.styleColors.magenta.fill
                        }),
                        stroke: new Stroke({
                            color: this.styleColors.magenta.stroke,
                            width: 3
                        })
                    })
                })
            });

            this.mapService.map().addLayer(this.layers.notificationLayer);
        }

        return this.layers.notificationLayer;
    }

    /**
     * Create a layer that is used for displaying clusters, this layer is not editable
     */
    clusterLayer(): any {
        if (!this.layers.clusterLayer) {
            const source: any = new Vector({ wrapX: false });

            // Create clusters
            const clusterSource = new Cluster({
                distance: parseInt('40', 10),
                source
            });

            const styleCache = {};
            this.layers.clusterLayer = new layerVector({
                source: clusterSource,
                minResolution: 3.359,
                style(feature: any) {
                    if (feature.get('features')) {
                        const size = feature.get('features').length;
                        let style = styleCache[size];
                        if (!style) {
                            style = new Style({
                                image: new Circle({
                                    radius: 10,
                                    stroke: new Stroke({
                                        color: '#fff'
                                    }),
                                    fill: new Fill({
                                        color: '#3399CC'
                                    })
                                }),
                                text: new Text({
                                    text: size.toString(),
                                    fill: new Fill({
                                        color: '#fff'
                                    })
                                })
                            });
                            styleCache[size] = style;
                        }

                        return style;
                    }
                }
            });

            this.mapService.map().addLayer(this.layers.clusterLayer);
        }

        return this.layers.clusterLayer;
    }

    /**
     * Create a layer that is used for displaying markers, this layer is not editable
     * The markerLayer will only display when the clusterLayer is hidden by it's minResolution property
     */
    markerLayer(): any {
        if (!this.layers.markerLayer) {
            const source: any = new Vector({ wrapX: false });

            this.layers.markerLayer = new layerVector({
                source,
                maxResolution: 3.358,
                style(feature: any) {
                    // List of colors per project title
                    const colors = {
                        'wegen/verkeer': 'red',
                        riolering: 'cyan',
                        'groen/bomen': 'green',
                        'openbare verlichting': 'yellow',
                        natuur: 'stripegreen',
                        'recreatie/toerisme': 'purple',
                        herinrichting: 'orange',
                        'gemeentelijke projecten': 'darkblue',
                        'projecten derden': 'darkerblue',
                        nutsbedrijven: 'stripered',
                        'projecten andere overheden': 'yellow'
                    };

                    // Define a default marker color
                    let marker_color = '';

                    // Set the marker color
                    if (feature.get('project')) {
                        const project = feature.get('project');
                        marker_color = colors[project.type_project];
                    }

                    // Return the proper feature style
                    const style = new Style({
                        fill: new Fill({
                            color: 'rgba(0, 51, 255, 0.5)'
                        }),
                        stroke: new Stroke({
                            color: '#0033ff',
                            width: 5
                        }),
                        image: new Icon({
                            anchor: [0.5, 46],
                            opacity: 1,
                            anchorXUnits: 'fraction',
                            anchorYUnits: 'pixels',
                            src: `assets/img/icons/map-marker-${marker_color}.png`
                        })
                    });

                    return style;
                }
            });

            this.mapService.map().addLayer(this.layers.markerLayer);

            return this.layers.markerLayer;
        }
    }

    measurementLayer(): any {
        if (!this.layers.measurementLayer) {
            const source = new Vector();

            this.layers.measurementLayer = new layerVector({
                source
            });

            this.layers.measurementLayer.setStyle(
                (feature: any, resolution: any) => {
                    const styles = [
                        // linestring style
                        new Style({
                            fill: new Fill({
                                color: 'rgba(255, 255, 255, 0.2)'
                            }),
                            stroke: new Stroke({
                                color: '#ffcc33',
                                width: 2
                            }),
                            image: new Circle({
                                radius: 7,
                                fill: new Fill({
                                    color: '#ffcc33'
                                })
                            }),
                            text: new Text({
                                text: feature.get('text')
                                    ? feature.get('text')
                                    : '',
                                textAlign: 'center',
                                offsetY: -30,
                                scale: 1.4,
                                overflow: true,
                                stroke: new Stroke({
                                    color: '#ffcc33',
                                    width: 3
                                }),
                                fill: new Fill({
                                    color: '#000'
                                })
                            })
                        })
                    ];

                    return styles;
                }
            );

            this.mapService.map().addLayer(this.layers.measurementLayer);
        }

        return this.layers.measurementLayer;
    }

    overviewSelectedLayer(): any {
        if (!this.layers.overviewSelectedLayer) {
            const source: any = new Vector({ wrapX: false });
            this.layers.overviewSelectedLayer = new layerVector({
                source
            });

            this.layers.overviewSelectedLayer.setStyle((feature: any) => {
                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');

                const pattern = (function () {
                    canvas.width = 8 * 1;
                    canvas.height = 8 * 1;
                    context.fillStyle = 'transparent';
                    context.fillRect(0, 0, canvas.width, canvas.height);
                    // inner circle
                    context.fillStyle = 'rgb(244, 148, 65)';
                    context.beginPath();
                    context.arc(4 * 1, 4 * 1, 1.5 * 1, 0, 2 * Math.PI);
                    context.fill();
                    return context.createPattern(canvas, 'repeat');
                })();

                return new Style({
                    stroke: new Stroke({
                        color: '#f49441',
                        width: 3
                    }),
                    fill: new Fill({
                        color: pattern
                    }),
                    image: new Circle({
                        radius: 7,
                        fill: new Fill({
                            color: 'rgba(255, 186, 25, 0)'
                        }),
                        stroke: new Stroke({
                            color: 'rgba(255, 186, 25, 0.5)',
                            width: 3
                        })
                    })
                });
            });

            this.mapService.map().addLayer(this.layers.overviewSelectedLayer);
        }

        return this.layers.overviewSelectedLayer;
    }

    redliningLayer(): any {
        if (!this.layers.redliningLayer) {
            const source = new Vector();
            this.layers.redliningLayer = new layerVector({
                source,
                style: function (feature: Feature) {
                    const featureText: string = feature.get('text') || '';

                    return new Style({
                        fill: new Fill({
                            color: 'rgba(247, 113, 73, 0.4)'
                        }),
                        stroke: new Stroke({
                            color: 'rgb(29, 1, 139)',
                            width: 4
                        }),
                        image: new Circle({
                            radius: 7,
                            fill: new Fill({
                                color: 'rgb(29, 1, 139)'
                            })
                        }),
                        text: new Text({
                            text: featureText,
                            textAlign: 'center',
                            offsetY: -20,
                            scale: 1.4,
                            stroke: new Stroke({
                                color: '#fff',
                                width: 3
                            }),
                            fill: new Fill({
                                color: '#000'
                            })
                        })
                    });
                }
            });

            this.mapService.map().addLayer(this.layers.redliningLayer);
        }

        return this.layers.redliningLayer;
    }

    dragAndDropLayer(): any {
        if (!this.layers.dragAndDropLayer) {
            const source: any = new Vector();

            this.layers.dragAndDropLayer = new layerVector({
                source,
                // Maybe add some styling
                style: new Style({
                    fill: new Fill({
                        color: this.styleColors.orange.fill
                    }),
                    image: new Circle({
                        radius: 7,
                        fill: new Fill({
                            color: this.styleColors.orange.fill
                        })
                    }),
                    stroke: new Stroke({
                        color: 'rgb(244, 148, 65)',
                        width: 4
                    }),
                    text: new Text({
                        textAlign: 'center',
                        offsetY: 40,
                        scale: 1.4,
                        stroke: new Stroke({
                            color: '#ffffff',
                            width: 3
                        }),
                        fill: new Fill({
                            color: this.styleColors.orange.fill
                        })
                    })
                })
            });

            this.mapService.map().addLayer(this.layers.dragAndDropLayer);
        }

        return this.layers.dragAndDropLayer;
    }

    bufferLayer(): any {
        if (!this.layers.bufferLayer) {
            const source: any = new Vector();

            this.layers.bufferLayer = new layerVector({
                source,
                style: new Style({
                    fill: new Fill({
                        color: this.styleColors.lightgrey.fill
                    }),
                    image: new Circle({
                        radius: 7,
                        fill: new Fill({
                            color: this.styleColors.lightgrey.fill
                        })
                    }),
                    stroke: new Stroke({
                        color: this.styleColors.lightgrey.stroke,
                        width: 4
                    }),
                    text: new Text({
                        textAlign: 'center',
                        offsetY: 40,
                        scale: 1.4,
                        stroke: new Stroke({
                            color: this.styleColors.lightgrey.stroke,
                            width: 3
                        }),
                        fill: new Fill({
                            color: this.styleColors.lightgrey.fill
                        })
                    })
                })
            });

            this.mapService.map().addLayer(this.layers.bufferLayer);
        }

        return this.layers.bufferLayer;
    }

    setStyle(layer): void {
        layer.setStyle(feature => {
            // Define a default color
            let color;

            // Check if there is styling in the config, if so apply it
            if (feature.get('styling')) {
                for (const prop of Object.keys(feature.get('styling'))) {
                    if (feature.get('styling')[prop]) {
                        if (typeof feature.get('styling')[prop] !== 'object') {
                            color = feature.get('styling')[prop];
                        } else {
                            for (const value of Object.keys(
                                feature.get('styling')[prop]
                            )) {
                                // Styling specific to a result of a feature
                                if (feature.get(prop) === value) {
                                    color = feature.get('styling')[prop][value];
                                } else {
                                    color = 'orange';
                                }
                            }
                        }
                    }
                }
            } else {
                // If there's no styling default is orange
                color = 'yellow';
            }

            // The last number defines the opacity. Making it 0 makes it fully transparent.
            const styleColors = this.styleColors;

            // Get the text from a feature
            const featureText = feature =>
                feature.get('text') ? feature.get('text') : '';

            const stroke = new Stroke({
                color: styleColors[color] ? styleColors[color].stroke : '#f49441',
                width: 4
            });

            // Return the proper feature style
            const style = new Style({
                fill: new Fill({
                    color: styleColors[color]
                        ? styleColors[color].fill
                        : 'rgba(244, 148, 65, 0.3)'
                }),
                stroke,
                image: new Circle({
                    radius: 7,
                    fill: new Fill({
                        color: styleColors[color]
                            ? styleColors[color].fill
                            : 'rgba(244, 148, 65, 0.3)'
                    }),
                    stroke
                }),
                text: new Text({
                    text: featureText(feature),
                    textAlign: 'center',
                    offsetY: 40,
                    scale: 1.4,
                    stroke: new Stroke({
                        color: '#ffffff',
                        width: 3
                    }),
                    fill: new Fill({
                        color: styleColors[color]
                            ? styleColors[color].stroke
                            : '#f49441'
                    })
                })
            });

            return style;
        });
    }
}
